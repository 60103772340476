    body {
        font-family: 'Arial', sans-serif;
        margin: 0;
        padding: 0;
        display: flex;
        height: 100vh;
        flex-direction: column;
        background-color: #ecf0f1;
    }

    .sidebar {
        width: 250px;
        background: #2c3e50;
        padding: 20px;
        border-right: 1px solid #ddd;
        position: fixed;
        height: 100%;
        overflow-y: auto;
        color: white;
        box-sizing: border-box;
    }

    select {
        width: 100%;
        padding: 10px;
        margin: 5px 0;
        border: 1px solid #ccc;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
    }

    #plant_selector{
        background-color: #1abc9c;
        color: black;
        border: none;
        font-weight: bold;
    } 
    /* */

    /* #uploadExcel {
        background-color: #e74c3c;
        color: white;
        font-weight: bold;
        border: none;
        cursor: pointer;
    }

    #uploadExcel:hover {
        background-color: #c0392b;
        transform: scale(1.05);
    } */

    .cards-container {
        margin-left: 270px;
        padding: 20px;
        flex: 1;
    }

    .charts-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 2 columns for quad layout */
        gap: 20px;
        margin-top: 20px;
        margin-left: 270px;
        padding: 0 20px;
    }

    .chart-box {
        background: #eff1e9;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        text-align: center;
        overflow: hidden;
    }

    .chart-box canvas {
        width: 100%;
        height: 300px; /* Set a height to make sure the chart is visible */
        display: block;
    }

    .chart-box:hover {
        transform: scale(1.05);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    }

    .card i {
        font-size: 30px;
        margin-bottom: 10px;
        display: block;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
    }

    table, th, td {
        border: 1px solid #ddd;
    }

    th {
        background: #34495e;
        color: white;
        padding: 10px;
    }

    td {
        padding: 10px;
        text-align: left;
    }

    .cards-container {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
    }
    .main-cards {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
        }


    .card {
            position: relative; /* Enables absolute positioning for child elements */
    background-color: #fff;
    border-radius: 10px;
    padding: 25px;
    text-align: center;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    flex: 1;
    margin: 0 15px;
    transition: transform 0.3s ease;
        }
        .card h3 {
            position: absolute;
    top: 10px;  /* Adjust top spacing */
    left: 10px; /* Adjust left spacing */
    font-size: 14px;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.8);
    color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        }
        .card h1 {
    font-size: 50px; /* Increase size for better visibility */
    color: #333;
    font-weight: 700;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center; /* Vertically align the text and percentage */
    justify-content: center;
}
.card p {
    font-size: 29px; /* Increase the font size */
    font-weight: bold;
    color: #f2f9f2; /* Ensure visibility against the background */
    margin-top: 10px;
}


    .card-title {
        position: absolute;
        top: 10px;
        left: 15px;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        opacity: 0.8;
    }

    .card:hover {
        transform: scale(1.05);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    }

    #dayCard{
        background-color: #3498db;
    }

    #weekCard {
        background-color: #2ecc71;
    }

    #monthCard {
        background-color: #f39c12;
    }

    #yearCard {
        background-color: #e74c3c;
    }

    .profile {
        text-align: center;
        padding: 10px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        margin-bottom: 20px;
    }

    .profile-pic {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 3px solid white;
        object-fit: cover;
    }

    .profile-name {
        margin: 10px 0 5px;
        font-size: 18px;
        font-weight: bold;
        color: white;
    }

    .profile-role {
        font-size: 14px;
        color: #bdc3c7;
        margin-bottom: 10px;
    }

    .sales-dashboard-title {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        color: #2c3e50;
        margin-bottom: 40px;
    }

    .charts-title {
        font-size: 24px;
        font-weight: bold;
        color: #2c3e50;
        text-align: center;
        margin-bottom: 20px;
    }

    /* Fix for table overlaying sidebar */
    .table-container {
        margin-left: 270px;
        padding: 20px;
        flex: 1;
    }
    table tbody tr:hover {
    background-color: #f1c40f; /* Highlight color when hovering */
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

    #yesterdayCard {
    background-color: #8e44ad; /* Purple */
}
